export const PRICELIST = {
  nails: [{
    name: "Manicure Klasyczny",
    description: "Zabieg polegający na skróceniu i opiłowaniu płytki, następnie zamknięciu łusek paznokcia pilnikiem oraz usunięciu cążkami narośniętych na płytkę skórek. Paznokieć polerujemy, malujemy i nakładamy oliwkę nawilżającą.",
    price: "60zł",
    time: "40min"
  }, {
    name: "Manicure hybrydowy ",
    description: "Trend w stylizacja paznokci, który od kilku lat cieszy się dużym powodzeniem. Stosowane tu lakiero-żele utwardza się w lampie UV/LED. Gwarantuje to nieskazitelny blask, niewiarygodną odporność na zarysowanie a co także zachęcające - nie wymaga schnięcia.",
    price: "80zł",
    time: "60min"
  },  
   {
    name: "Manicure japoński ",
    description: "Zabieg polegający na wcieraniu pasty z witaminami i minerałami w naturalną płytkę paznokciową oraz naniesieniu na nią warstwy pudru. W efekcie uzyskuje się zdrowe i naturalnie wyglądające paznokcie.",
    price: "70zł",
    time: "50min"
  }, {
    name: "Zabieg parafinowy na dłonie",
    description: "Zabieg ma działanie rozgrzewajace , wygładzające i nawilżajace. Zabieg parafinowy na dłonie przynosi ulgę dla przesuszonych , zniszczonych i spierzchnietych dłoni",
    price: "50zł",
    time: "30min"
  },
  {
    name: "Przedłużanie paznokci żelem ",
    description: "",
    price: "140zł",
    time: "120min"
  },{
    name: "Przedłużanie paznokci żelem - dopełnienie",
    description: "",
    price: "110zł",
    time: "90min"
  },],
  feet: [{
    name: "Pedicure klasyczny z malowaniem klasycznym",
    description: "Pedicure klasyczny to podstawowy zabieg kosmetyczny, który pozwoli cieszyć się pięknymi i zdrowymi stopami. Wykonują go nie tylko kobiety, lecz także coraz więcej mężczyzn. Pozwala na pozbycie się zrogowaciałego naskórka, który daje o sobie znać szczególnie na piętach",
    price: "100zł",
    time: "40min",
  },
  {
    name: "Pedicure klasyczny z malowaniem hybrydowym",
    description: "Pedicure klasyczny z malowaniem hybrydowym to podstawowy zabieg kosmetyczny, który pozwoli cieszyć się pięknymi i zdrowymi stopami. Wykonują go nie tylko kobiety, lecz także coraz więcej mężczyzn. Pozwala na pozbycie się zrogowaciałego naskórka, który daje o sobie znać szczególnie na piętach",
    price: "110zł",
    time: "60min",

  },
  {
    name: "Pedicure na bazie kwasów Callus Peel",
    description: "Pedicure na bazie kwasów Callus Peel to pedicure wykonany za pomocą zaawansowanych technologicznie kwasów kosmetycznych. Kwasy te z łatwością zmiękczają twardy, zrogowaciały naskórek stopy. Szczególnie dobrze radzą sobie z grubą warstwą zrogowaciałego i przesuszonego naskórka pięt skłonnych do pękania.",
    price: "130zł",
    time: "60min"
  }],
  face: [{
    name: "Zabieg oczyszczajacy twarzy (manualny) ",
    description: "Zabieg polecany jest szczególnie dla osób mający problem z zaskórnikami, rozszerzonymi porami oraz borykającymi się z trądzikiem",
    price: "150zł",
    time: "60min"
  },
  {
    name: "Peeling kawitacyjny z masażem twarzy + maska ",
    description: "Popularny zabieg kosmetologii pielęgnacyjnej, który dokładnie oczyszcza skórę przez działanie fal ultradźwiękowych. Usuwa nadmiar sebum z porów i mieszków włosowych, zrogowaciały naskórek, bakterie, oraz zaskórniki.",
    price: "150zł",
    time: "60min"
  },
  {
    name: "Mikrodermabrazja Diamentowa + maska",
    description: "Efektem jest głębokie oczyszczanie, a martwy, złuszczany podczas zabiegu naskórek, jest od razu zasysany pompą umieszczoną na głowicy. Tak mocny peeling stymuluje skórę do wzmożonej produkcji nowych komórek naskórka. Można wykonywać go na twarzy, plecach i dekolcie.",
    price: "150zł",
    time: "60min"
  },
  {
    name: "Zabieg kwasami Natinuel - Kwas Migdałowy 40 %",
    description: "Jest zabiegiem odmładzająco-regenerującym. przeznaczony do pielęgnacji każdego rodzaju skóry twarzy i ciała, również tej najbardziej delikatnej.",
    price: "150zł",
    time: "60min"
  },
  {
    name: "Zabieg kwasami Natinuel - Kwas Glikolow",
    description: "Przyspiesza wymianę komórek,reaktywuje mechanizmy energetycznego,pobudza syntezę nowego kolagenu, elastyny i GAG.",
    price: "150zł",
    time: "60min"
  },
  {
    name: "Mezoterapia Mikroigłowa",
    description: "Mezoterapia Mikroigłowa to jeden z najpopularniejszych zabiegów odmładzających i regenerujących skórę. Jest bezpieczna i mało inwazyjna, a w odróżnieniu od klasycznej mezoterapii, nie wymaga użycia strzykawki.",
    price: "170zł",
    time: "60min"

  },
  {
    name: "Oczyszczanie wodorowe twarzy",
    description: "Oczyszczanie wodorowe twarzy to zabieg kosmetyczny wykorzystujący działanie wodoru do głębokiego oczyszczania skóry, usuwania zanieczyszczeń i poprawy jej wyglądu.",
    price: "200zł",
    time: "60min"

  }],
  eyes: [{
    name: "Laminacja rzęs",
    description: "Wyraźnie upiększa  rzęsy i, co równie ważne, dba o ich kondycję. Po laminowaniu rzęsy stają się mocniejsze, lepiej nawilżone oraz lekko zawinięte i uniesione",
    price: "110zł",
    time: "60min"
  },
  {
    name: "Laminacja brwi",
    description: "Zabieg laminacji brwi polega na ułożeniu i zmianie kierunku rosnących włosków. Zabieg ten szczególnie polecamy kobietom, które mają niesfornie kręcące się brwi, sztywne lub rosnące w dół, a także cienkie, gdyż tym zabiegiem możemy je pogrubić i nadać prawidłowe ułożenie.",
    price: "110zł",
    time: "60min"
  },
  {
    name: "Przedłużanie rzęs metodą 1:1",
    description: "Rzęsy po zabiegu przedłuania metodą 1:1 wyglądaja niezwykle naturalnie. Zabieg polega na doklejeniu do jednej naturalnej rzęsy jednej sztucznej. Dzięki temu można osiągnąć efekt gęstych, długich i podkręconych rzęs.",
    price: "150zł",
    time: "120min"
  },
  {
    name: "Przedłużanie rzęs metodą objetosciowa",
    description: "Przedłużanie rzęs metodą objetosciowa jest na tyle spektakularne, że powinno zadowolić nawet najbardziej wymagającego klienta. Wykonuje się je w taki sam sposób, jak aplikację 1:1, tyle że tutaj przykleja się nie pojedynczy włosek, a całą kępkę",
    price: "180zł",
    time: "120min"
  },
  {
    name: "Henna i regulacja brwi",
    price: "30zł",
    time: "30min"
  }
],
  makeup: [{
    name: "Makijaż dzienny",
    description: "Makijaż dzienny",
    price: "90zł",
  }, {
    name: "Makijaż wieczorowy",
    description: "Makijaż wieczorowy",
    price: "90zł",
    time: "60min"
  },
  {
    name: "Makijaż permanentny brwi",
    description: "Makijaż permanentny brwi jest doskonałą alternatywą dla wszystkich kobiet, które nie chcą lub nie mogą codziennie ich malować. Osoby, które decydują się na zabieg makijażu permanentnego brwi , często robią go nie tylko, aby zaoszczędzić czas, lecz także by poprawić naturalny kształt brwi.",
    price: "700zł",
    time: "3h"
  }],
  waxing: [{
    name: "Depilacja woskiem - całe nogi",
    price: "100zł",
  },
  {
    name: "Depilacja woskiem - łydki",
    price: "60zł",
  },
  {
    name: "Depilacja woskiem - bikini",
    price: "50zł",
  },
  {
    name: "Depilacja woskiem - wąsik",
    price: "30zł",
  },
  {
    name: "Depilacja woskiem - pachy",
    price: "50zł",
  },
  {
    name: "Depilacja woskiem - ręce",
    price: "50zł",
  }]
}
